@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink&display=swap');


.search-input {
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    
    justify-content: flex-start !important;
    align-items: center;
    border-radius: 100rem;
   height: 45px;
   width: 480px;
  
    position: relative;
   
    // animation: drop 600ms alternate ease-in-out;
    
    
    @media screen and (max-width: 800px) {
      width: 85vw;
      margin: auto;
    }
    
    .main {
      display: flex;
      
      align-items: center;
      margin-left: 12px;
      width: 480px !important;
      input {
        border: none;
        background: none;
        outline: none;
        float: left;
        margin: 0 !important;
        color: black;
        font-size: 20px;
        transition: 0.4s;
        width: 100% !important;
       
        padding: 0 6px;

        &::placeholder {
          color: gray;
          font-size: 20px !important;
          font-family: 'Reem Kufi Ink', sans-serif !important;
        }
      }
    }
    .btn {
      
      height: 41px;
      width: 41px;
      border-radius: 100rem;
      
      display: flex;
    position: absolute;
      justify-content: center;
      align-items: center;
      right: 2px;
      // animation: down 600ms alternate ease-in-out;
      cursor: pointer;

     
      
      a {
        display: flex;
        position: relative;
          justify-content: center;
          align-items: center;
          
        svg {
          color: rgb(245, 50, 50) !important;
          font-size: 23px;
          // animation: down 600ms alternate ease-in-out;
          
          
        }
      }

    }
    
  }
  