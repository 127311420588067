@import"../../variable.scss";
.watching {
  display: flex;
  padding: 1% 5%;
  position: relative;

  .left-container {
    flex: 2;
    margin: 0 20px;
  }
  .right-container {
    flex: 1;
    // display: grid;
    // place-content: center;
  }
  @media screen and (max-width: 1000px) {
    .right-container {
      flex: 1;
      display: grid;
      place-content: center;
    }
  }
}

.watching-container {
  .heading {
    p {
      font-size: 13px;
      margin: 1rem 0;

      span {
        color: $primary-red;
      }
    }
  }
  .card {
    h1 {
      font-size: 24px !important;
      margin: 1rem 0;
      font-weight: 400;
    }
    p {
      font-size: 13px;
      margin: 1rem 0;
      span {
        font-size: 15px;
        color: $primary-watching;
      }
    }

    .share-buttons {
      text-decoration: none;

      width: 100%;
      height: 100%;
      display: flex;

      align-items: center;
      transition: transform 500ms ease-in-out;
    }
    .download-btn {
      --width: 100px;
      --height: 40px;
      border: 0;
      position: relative;
      min-width: var(--width);
      min-height: var(--height);
      border-radius: var(--height);
      color:$primary-white  !important;

      font-weight: bold;
      background: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin: 0 1rem;
      background-color: $primary-hamburger-menu;

      transition: all 500ms ease-in-out;
      .download-link {
        text-decoration: none;

        width: 100%;
        height: 100%;
        display: flex;

        align-items: center;
        transition: transform 500ms ease-in-out;
      }
      .text,
      .icon-container {
        position: relative;
        z-index: 2;
      }

      .text {
        font-weight: 400;
        color: $primary-white;
        margin-left: 15px;
      }

      .icon-container {
        --icon-size: 25px;
        position: relative;
        width: 25px;
        height: 25px;
        margin-left: 22px;
        transition: all 500ms ease-in-out;

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          width: var(--icon-size);
          height: var(--icon-size);
          transition: all 500ms ease-in-out;

          display: flex;
          justify-content: center;
          align-items: center;

          &--left {
            transform: translateY(200%);
            opacity: 0;
            color:$primary-white;
          }
          &--right {
            transform: translateX(-25%);
            color:$primary-white;
          }
          @media screen and (max-width: 800px) {
            &--right {
              transform: translateX(13%);
            }
          }

          svg {
            width: 100%;
            height: 100%;
            fill: $primary-white;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-red;
        border-radius: 100rem;
        z-index: 1;
        transition: all 500ms ease-in-out;
        transform: translateX(68%);
        @media screen and (max-width: 800px) {
          transform: translateX(67%);
        }
      }

      &:hover {
        transition: transform 500ms ease-in-out;
        &::after {
          transform: translateX(0%);
        }

        .icon-container {
          transition: transform 500ms ease-in-out;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateX(40%);

          .icon {
            &--left {
              transform: translateX(-70%);
              opacity: 1;
              color: $primary-white;
            }
            &--right {
              transform: translateX(200%);
              opacity: 0;
            }
          }

          @media screen and (max-width: 800px) {
            transform: translateX(50%);
          }
        }
      }
      @media screen and (max-width: 800px) {
        --width: 120px;
        .icon-container {
          margin-left: 10px;
        }
      }
    }
    #iframe-container {
      width: 100%;
      height: 470px;

      
      @media screen and (max-width: 1200px) {
        height: 480px;
      }
      @media screen and (max-width: 1000px) {
        height: 560px;
      }
      @media screen and (max-width: 900px) {
        height: 480px;
      }
      @media screen and (max-width: 800px) {
        height: 440px;
      }
      @media screen and (max-width: 750px) {
        height: 400px;
      }
      @media screen and (max-width: 700px) {
        height: 360px;
      }
      @media screen and (max-width: 650px) {
        height: 340px;
      }
      @media screen and (max-width: 600px) {
        height: 310px;
      }
      @media screen and (max-width: 550px) {
        height: 282px;
      }
      @media screen and (max-width: 500px) {
        height: 260px;
      }
    }
  }
}

.drama-detail {
  .card {
    display: flex;
    gap: 2rem;
    .image {
      img {
        width: 240px !important;
        height: 320px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
    p {
      margin: 1rem 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .watching {
    display: block;

    padding: 1%;

    .right-container {
      padding: 1% 5%;
    }
  }
}
@media screen and (max-width: 800px) {
  .watching {
    display: block;

    padding: 1%;

    .right-container {
      padding: 1% 5%;
    }
  }
}
