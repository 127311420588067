.terms-condition,.policy {
    color:black;
    padding:3rem 2rem;

   h1, h2,h3 {
        font-size: 28px;
        opacity: 0.7;
        margin: 1rem 0; 
    }
    h1 {
        font-size: 35px;
    }
    a {
        text-decoration: none;
        color: red;
    }
    ul {
        li {
            list-style-type:lower-alpha;
        }
    }

}