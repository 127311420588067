@import"../../variable.scss";

.serarch-result-heading {
  padding: 0% 5%;
  h1 {
    font-size: 25px;
    font-weight: 500;

    margin: 1rem 0;
    span {
      color: $primary-red;
    }
  }
}
.search-notfound {
  padding: 0% 5%;
  font-size: 25px;
  height: 50vw;

}
.search-drama {
    padding: 2% 5%;
  
    @media screen and (max-width: 800px) {
      margin-top: 1rem;
    }
  
   
    .search-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(12%, 180px)) !important;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      .card {
        margin-bottom: 1rem;
  
        a {
          text-decoration: none;
          color: $primary-black;
          span {
            height: 83% !important;
            border-radius: 6px;
          }
          img {
            width: 100%;
            position: relative !important;
            height: 250px !important;
            border-radius: 6px;
            object-fit: cover;
            background-size: auto;
          }
  
          .image-container {
            height: 250px !important;
            position: relative !important;
            img {
              width: 100%;
  
              height: 100% !important;
              border-radius: 6px;
              object-fit: cover;
            }
            &::after {
              content: "";
              background-color: $primary-black;
              width: 100%;
              height: 100%;
              opacity: 0.4;
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 6px;
              display: none;
            }
            .play-icon {
              position: absolute;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 35px;
              display: none;
              color: $primary-white;
            }
  
            @media screen and (max-width: 325px) {
              height: 200px !important;
            }
          }
  
          p {
            font-size: 14px;
  
            margin-bottom: 1rem;
            margin-left: 2px;
          }
          span {
            color: $primary-gray;
            font-size: 13px;
          }
  
          &:hover {
            .image-container {
              &::after {
                display: block;
              }
              img {
                filter: blur(1px);
              }
            }
            .play-icon {
              display: block;
              z-index: 3;
            }
          }
        }
      }
      @media screen and (max-width: 425px) {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)) !important;
  
        img {
          height: 250px !important;
        }
      }
      @media screen and (min-width: 2550px) {
        grid-template-columns: repeat(auto-fit, minmax(10%, 180px)) !important;
        img {
          height: 270px !important;
        }
      }
      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(18%, 150px)) !important;
      }
      @media screen and (max-width: 604px) {
        grid-template-columns: 1fr 1fr !important;
        img {
          height: 240px !important;
        }
      }
      @media screen and (max-width: 374px) {
        grid-template-columns: 1fr 1fr !important;
    
        img {
          height: 200px !important;
        }
      }
    }
  }
  