* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import "./variable.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

body,
html {
  font-family: "Poppins", sans-serif;
  min-width: 100vw !important;
  transition: all 500ms ease-in-out;
  overflow-x: hidden;
  min-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.top {
  position: fixed;

  background-color: $primary-red !important;
  width: 40px !important;
  height: 40px !important;
  color: $primary-white;
  display: grid;
  place-items: center;
  bottom: 20px;
  right: 20px !important;
  z-index: 100;
  cursor: pointer;
  border: none;
  border-radius: 20000px;
  transition: all 500ms ease-in-out;

  svg {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: all 500ms ease-in-out;
  }
}

.dark {
  background-color: $dark-mode-back;
  color: $primary-white;
  min-height: 100vh;
  transition: all 0.1s ease-in-out !important;

  .intro-page {
    // background-color: $dark-mode-back !important;
    .top-background {
      z-index: 12;
      &::after {
        background: linear-gradient(
            0deg,
            rgb(8, 8, 8) 25%,
            rgba(8, 8, 8, 0) 100%
          ),
          url("./assets/back.jpg") repeat-x !important;
        z-index: -2;
        height: 100%;
      }
      @media screen and (max-width: 800px) {
        &::after {
          background: linear-gradient(
              0deg,
              rgb(8, 8, 8) 15%,
              rgba(8, 8, 8, 0) 100%
            ),
            url("./assets/background.webp") repeat-x !important;
          height: 100%;
        }
      }
      .top-wrapper {
        .search-consoule {
          .search-input {
            box-shadow: rgb(197, 195, 195) 0px 0px 0px 2px,
              rgba(255, 255, 255, 0.459) 0px 1px 0px inset;
            .main {
              input {
                color: $primary-white;
                &::placeholder {
                  color: $primary-white;
                }
              }
            }
          }
        }
      }
    }
    .site-text {
      h1 {
        color: rgb(124, 124, 124);
      }
    }
  }

  nav {
    .navbar {
      .search-wrapper {
        .heading {
          a {
            h1 {
              color: $primary-white;
            }
          }
        }
        .search {
          .search-input {
            box-shadow: rgba(255, 255, 255, 0.247) 0px 2px 8px 0px;

            .main {
              input {
                color: $primary-white;

                &::placeholder {
                  color: $primary-white;
                }
              }
            }
          }
        }
      }
      .menu-btn {
        .search-btn,
        .hamburger-btn {
          svg {
            color: $primary-white;
          }
        }
      }
    }
    .search-box {
      .search-input {
        box-shadow: rgba(255, 255, 255, 0.247) 0px 2px 8px 0px;

        .main {
          input {
            color: $primary-white;

            &::placeholder {
              color: $primary-white;
            }
          }
        }
      }
    }
  }
  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      .asiandrama {
        .drama {
          .card {
            p {
              color: $primary-white;
            }
            span {
              color: rgb(172, 168, 168);
            }
          }
        }
      }
    }
  }
  .watching {
    .left-container {
      .watching-container {
        .episodes {
          .episodes-container {
            a {
              color: $primary-white;
            }
            .active {
              color: $primary-black;
            }
          }
        }
      }
    }
    .right-container {
      .recommend {
        .recommend-container {
          .card {
            .title {
              color: $primary-white;
              span {
                color: rgb(172, 168, 168);
              }
            }
            span {
              color: rgb(172, 168, 168);
            }
          }
        }
      }
    }
  }
  .lds-ellipsis {
    div {
      background-color: $primary-white;
    }
  }
  .footer {
    background-color: $dark-mode-footer;
    color: $primary-white !important;
    .logo {
      color: $primary-white;
      
        .logo-text {
          color: $primary-white;
        
      }
    }
    .footer-info {
      .btn-links {
        .redirect {
          h3 {
            color: $primary-white;
          }
          &:hover {
            h3 {
              color: $primary-red;
            }
          }
        }
      }
    }
  }
}
