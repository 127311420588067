

    $primary-red:red;
    $primary-black:black;
    $primary-white:white;
    $primary-gray:gray;
    $primary-watching:rgb(241, 20, 20);
    $primary-nav-background:rgb(197, 192, 192);
    $primary-nav-back-hover:rgb(168, 165, 165);
    $primary-hamburger-menu:rgb(70, 68, 68);
    $dark-mode-back:rgb(8, 8, 8);
    $dark-mode-footer:rgba(8, 8, 8, 5);






    
    
