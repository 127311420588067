@import "../../../variable.scss";

.episodes {
  .heading {
    h1 {
      margin: 1rem 0 !important;
      font-weight: 500 !important;
    }
  }
  .episodes-container {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    transition: all 500ms ease-in-out;

    
    .episode-card {
      padding: 0.5rem !important;
      border: 1.5px solid $primary-gray;
      // background-color: rgb(247, 114, 114);
      border-radius: 6px;
      height: 50px;
      margin: 1rem 0;

      // border-radius: 1rem;

      object-fit: cover;

      .episode {
        display: flex;
        justify-content: space-between !important;
        height: 100%;
        align-items: center;
        text-align: center;

        margin: auto;
        font-weight: 500;

        p {
          font-size: 16px;
          margin: 5px 12px;
        }
        span {
          font-size: 15px;
          margin: 5px 12px;
        }
      }
      &:hover {
        border: 1.5px solid $primary-watching;
      }
    }
    a {
      text-decoration: none;
      color: $primary-black;

      height: 100%;

      &:hover {
        color: $primary-watching;
      }
    }
    .active {
      color: $primary-white;
      .episode-card {
        background-color: $primary-watching !important;
        border: none;
      }
      &:hover {
        color: $primary-white !important;
      }
    }

    @media screen and (max-width: 1000px) {
      a {
        .episode-card {
          padding: 0.5rem;
          .episode {
            p {
              font-size: 15px !important;
              letter-spacing: none;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 600px) {
      a {
        .episode-card {
          padding: 0.5rem;
          .episode {
            p {
              font-size: 14px !important;
              letter-spacing: none;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
